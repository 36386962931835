<template>
  <header :class="headerType">
    <div class="app-logo">
      <router-link to="/">
        <div class="app-logo__full"><span>andréa</span>delre</div>
        <div class="app-logo__small"><span>a</span></div>
      </router-link>
    </div>
    <nav>
      <!--<router-link to="/">travaux</router-link>
      <router-link to="/about">lecture</router-link>-->
      <router-link to="/outils">outils</router-link>
      <router-link to="/partage">partage</router-link>
    </nav>
  </header>
  <router-view :class="headerType"/>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const headerType = computed(() => store.getters.getHeaderType);
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800;900&display=swap');
#app {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem;
  position: absolute;
  width: 100vw;
  box-sizing: border-box;
}
header.blue a{
  color: white;
}
header.blue .app-logo__small {
  display: none;
}
header.blue .app-logo a:after {
  background-color: white;
}

header.grey .app-logo a:after {
  background-color: var(--blue-main);
}
header.grey .app-logo__full {
  display: none;
}
header.grey .app-logo__small {
  color: var(--blue-main);
}
header.grey nav {
  color: var(--grey-dark);
}

header .app-logo{
  font-size: 3rem;
}
header .app-logo span {
  font-weight: 800;
}
header nav {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
  font-weight: 500;
}

@media (max-width: 600px) {
  header {
    padding: 2rem;
  }
  header .app-logo__small, header.blue .app-logo__small, header.grey .app-logo__small {
    display: block;
  }
  header .app-logo__full {
    display: none;
  }
}
</style>
