import { createApp } from 'vue';
import './style.css';
import './registerServiceWorker';
import router from './router';
import store from './store';
import App from './App.vue';

createApp(App).use(store).use(router).mount('#app');

router.afterEach((to) => {
  store.commit('headerTypeTo', to.meta.pageType);
});
