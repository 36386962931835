import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      pageType: 'blue',
      title: 'Bienvenue sur mon portfolio',
    },
  },
  {
    path: '/partage',
    name: 'partage',
    component: () => import('../views/BlogView.vue'),
    meta: {
      pageType: 'grey',
      title: 'Partage',
    },
  },
  {
    path: '/partage/:year/:url',
    name: 'post',
    component: () => import('../views/PostView.vue'),
    meta: {
      pageType: 'grey',
      title: 'Article',
    },
  },
  {
    path: '/outils',
    name: 'tools',
    component: () => import('../views/ToolsView.vue'),
    meta: {
      pageType: 'grey',
      title: 'Outils',
    },
  },
  {
    path: '/outils/cohort-generator',
    name: 'cohort-generator',
    component: () => import('../views/tools/CohortGenerator.vue'),
    meta: {
      pageType: 'grey',
      title: 'Générateur de cohortes',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
