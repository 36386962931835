import { createStore } from 'vuex';
import POSTS from '@/posts/posts.json';

export default createStore({
  state: {
    headerType: 'blue',
    postsByPeriod: POSTS,
  },
  getters: {
    getHeaderType(state) {
      return state.headerType;
    },
    getAllPosts(state) {
      let allPosts = [];

      for (let i = 0; i < state.postsByPeriod.length; i += 1) {
        allPosts = allPosts.concat(state.postsByPeriod[i].posts);
      }

      return allPosts;
    },
  },
  mutations: {
    headerTypeTo(state, color) {
      state.headerType = color;
    },
  },
  actions: {
  },
  modules: {
  },
});
