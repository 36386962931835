<template>
  <div class="home page">
    <p class="home__about">
      Hey, je m’appelle Andréa et j’aime explorer le design
      à travers différents prismes comme <b>la data, la psychologie</b>
       et <b>la recherche utilisateur.</b>
    </p>
    <div class="home__social-networks">
      <a href="https://www.linkedin.com/in/andreadelre-ux-designer/" target="_blank">
        <span>linkedin</span>
        <img src="@/assets/logo-linkedin.svg" alt="" aria-hidden="true">
      </a>
      <a href="https://twitter.com/andreadelre" target="_blank">
        <span>twitter</span>
        <img src="@/assets/logo-twitter.svg" alt="" aria-hidden="true">
      </a>
      <a href="https://github.com/AndreaDelre" target="_blank">
        <span>github</span>
        <img src="@/assets/logo-github.svg" alt="" aria-hidden="true">
      </a>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
.home {
  background-color: var(--blue-main);
}
.home__about {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  padding: 0 5rem;
  line-height: 4.3rem;
  font-size: 3rem;
}
.home__social-networks {
  display: flex;
  position: absolute;
  bottom: 2rem;
  left: 5rem;
}
.home__social-networks a{
  display: flex;
  align-items: center;
}
.home__social-networks a {
  margin-right: 16px;
}
.home__social-networks a span {
  display: none;
}
@media (max-width: 600px) {
  .home__about {
    font-size: 1.5rem;
    padding: 0 2rem;
    line-height: 2rem;
  }
  .home__social-networks {
    left: 2rem;
  }
}
</style>
